<template>
  <div class="projects-list">
    <Background></Background>
    <div class="row  mt-s  mb-m  mb-xs-xs  pos-relative">
      <div class="col-24">
        <h2 class="h2  mb-0  mt-0  color-white  font-weight-normal" :class="{'font-size-xl  font-weight-medium': $matchMedia.xs}">{{ $t('projectsList.headline') }}</h2>
      </div>
    </div>
    <ProjectList :defaultFilter="filter" :defaultSort="sort"></ProjectList>
  </div>
</template>

<script>
import Background from '@/components/atoms/Background.vue';
import ProjectList from '@/components/modules/Project/List.vue';

export default {
  name: 'UserProjects',
  data() {
    return {
      filter: {
        application_deadline: true,
      },
      sort: {
        value: 'published_at',
        direction: 'desc',
      },
    };
  },
  components: {
    ProjectList,
    Background,
  },
};
</script>
