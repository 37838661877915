<template>
  <div class="file-picker form-element font-size-base  font-weight-medium  line-height-1">
    {{ }}
    <ul>
      <template v-for="file in files">
        <li :key="file.id" class="display-flex">
          <InputRadio class="-xsmall" id="files" :name="name" :choice="file.id" :disabled="disabled" :value="value" @input="onInput" :title="file.filename">
            <template slot="label">
              {{ file.filename }}
            </template>
          </InputRadio>
          <Link @click.native="$emit('show-file', { file })"><Icon class="-size-14"><LaunchSVG /></Icon></Link>
        </li>
      </template>
    </ul>
    <div class="error-msg" v-if="$te(`${base}.inputs.${name}.error`)">
      <slot name="errors">
        {{ $t(`${base}.inputs.${name}.error`) }}
      </slot>
    </div>
  </div>
</template>

<script>
import LaunchSVG from '@/assets/icons/launch.svg';
import Link from '@/components/atoms/Link.vue';
import Icon from '@/components/atoms/Icon.vue';
import InputRadio from '@/components/elements/inputs/InputRadio.vue';

export default {
  props: {
    name: String,
    base: String,
    files: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
    value: String,
  },
  data() {
    return {};
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
  },
  components: {
    LaunchSVG,
    Link,
    Icon,
    InputRadio,
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/file-picker.scss"></style>
