export default (value) => {
  if (!value) return value;

  const parts = value.split('@');

  if (parts.length !== 2) return value;
  parts[1] = parts[1].toLowerCase();

  return parts.join('@');
};
