<template>
  <div class="user-profile">
    <Background></Background>
    <UserHeader :user="{ ...user, ...usermeta, group }"
                :avatar="avatar">
    </UserHeader>
    <div class="row" v-if="$matchMedia.md">
      <!-- Mobile -->
      <div class="col-24">
        <ManagerProfileInfo v-if="user.uuid"
                            :uuid="user.uuid"
                            :jobTitle="user.jobTitle"
                            :linkedin="user.linkedin"
                            :xing="user.xing"
                            :website="user.website"
                            :username="user.username"
                            :updatedAt="user.updatedAt"
                            @update="fetchUser">
        </ManagerProfileInfo>
        <ManagerDocumentManager v-if="user.uuid" :user="{...user, ...usermeta }" />
        <SubscribeMarketingMails v-if="user.uuid"
                                 @update="fetchUser"
                                 :user="{ uuid: user.uuid, subscribe_projects: user.subscribe_projects }">
        </SubscribeMarketingMails>
        <ManagerDailyRate v-if="user.uuid"
                          :user="{...user, ...usermeta }"
                          @update="fetchUser">
        </ManagerDailyRate>
        <UserAvailability v-if="user.uuid"
                          :user="{ ...user, ...usermeta }"
                          @update="fetchUser">
        </UserAvailability>
        <ManagerSkills v-if="user.uuid"
                       :userUuid="user.uuid"
                       :skills="user.skills"
                       @update="fetchUser">
        </ManagerSkills>
        <keep-alive>
          <component :is="showCvVersion"
                     v-if="user.uuid"
                     v-bind="cvProps"
                     @switchCvView="handleCvSwitch"
                     @update="fetchUser">
          </component>
        </keep-alive>
        <UserEducation v-if="user.uuid && !user.keep_old_cv"
                       :uuid="user.uuid"
                       :entries="user.education_entries"
                       @update="fetchUser">
        </UserEducation>
        <UserCategories v-if="user.uuid"
                        :userUuid="user.uuid"
                        :categories="userCategories"
                        @update="fetchUser">
        </UserCategories>
        <UserWillingnessToTravel v-if="user.uuid"
                                 :user="{ ...user, ...usermeta }"
                                 @update="fetchUser">
        </UserWillingnessToTravel>
        <UserLanguageSkills v-if="user.uuid"
                            :user="user"
                            @update="fetchUser">
        </UserLanguageSkills>
        <UserMeta v-if="user.uuid"
                  :user="{ ...user, ...usermeta }"
                  :avatar="avatar"
                  @update="fetchUser">
        </UserMeta>
      </div>
    </div>
    <div class="row" v-if="!$matchMedia.md">
      <!-- Desktop -->
      <div class="col-18 col-xs-24">
        <ManagerSkills v-if="user.uuid"
                       :userUuid="user.uuid"
                       :skills="user.skills"
                       @update="fetchUser">
        </ManagerSkills>
        <keep-alive>
          <component :is="showCvVersion"
                     v-if="user.uuid"
                     v-bind="cvProps"
                     @switchCvView="handleCvSwitch"
                     @update="fetchUser">
          </component>
        </keep-alive>
        <UserEducation v-if="user.uuid && !user.keep_old_cv"
                       :uuid="user.uuid"
                       :entries="user.education_entries"
                       @update="fetchUser">
        </UserEducation>
        <UserCategories v-if="user.uuid"
                        :userUuid="user.uuid"
                        :categories="userCategories"
                        @update="fetchUser">
        </UserCategories>
        <UserMeta v-if="user.uuid"
                  :user="{ ...user, ...usermeta }"
                  :avatar="avatar"
                  @update="fetchUser">
        </UserMeta>
      </div>
      <div class="col-6 col-xs-24">
        <ManagerProfileInfo v-if="user.uuid"
                            :uuid="user.uuid"
                            :jobTitle="user.jobTitle"
                            :linkedin="user.linkedin"
                            :xing="user.xing"
                            :website="user.website"
                            :username="user.username"
                            :updatedAt="user.updatedAt"
                            @update="fetchUser">
        </ManagerProfileInfo>
        <ManagerDocumentManager v-if="user.uuid" :user="{...user, ...usermeta }" />
        <SubscribeMarketingMails v-if="user.uuid"
                                 @update="fetchUser"
                                 :user="{ uuid: user.uuid, subscribe_projects: user.subscribe_projects }">
        </SubscribeMarketingMails>
        <ManagerDailyRate v-if="user.uuid"
                          :user="{...user, ...usermeta }"
                          @update="fetchUser">
        </ManagerDailyRate>
        <UserAvailability v-if="user.uuid"
                          :user="{ ...user, ...usermeta }"
                          @update="fetchUser">
        </UserAvailability>
        <UserWillingnessToTravel v-if="user.uuid"
                                 :user="{ ...user, ...usermeta }"
                                 @update="fetchUser">
        </UserWillingnessToTravel>
        <UserLanguageSkills v-if="user.uuid"
                            :user="user"
                            @update="fetchUser">
        </UserLanguageSkills>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Background from '@/components/atoms/Background.vue';
import ManagerDailyRate from '@/components/modules/User/ManagerDailyRate.vue';
import ManagerProfileInfo from '@/components/modules/User/ManagerProfileInfo.vue';
import ManagerDocumentManager from '@/components/modules/User/ManagerDocumentManager.vue';
import ManagerSkills from '@/components/modules/User/ManagerSkills.vue';
import UserAvailability from '@/components/modules/User/UserAvailability.vue';
import UserCategories from '@/components/modules/User/UserCategories.vue';
import UserHeader from '@/components/modules/User/UserHeader.vue';
import UserLanguageSkills from '@/components/modules/User/UserLanguageSkills.vue';
import UserMeta from '@/components/modules/User/UserMeta.vue';
import UserWillingnessToTravel from '@/components/modules/User/UserWillingnessToTravel.vue';
import SubscribeMarketingMails from '@/components/modules/User/SubscribeMarketingMails.vue';

export default {
  name: 'UserProfileDetail',
  data() {
    return {
      switchCvView: false,
    };
  },
  computed: {
    ...mapState([
      'uuid',
      'usermeta',
      'user',
      'group',
      'avatar',
    ]),

    userCategories() {
      return {
        main_operationals: (this.user.main_operationals.length > 0) ? this.user.main_operationals : [],
        sub_operationals: (this.user.sub_operationals.length > 0) ? this.user.sub_operationals : [],
        main_industries: (this.user.main_industries.length > 0) ? this.user.main_industries : [],
        sub_industries: (this.user.sub_industries.length > 0) ? this.user.sub_industries : [],
      };
    },

    showCvVersion() {
      return (this.user.keep_old_cv === this.switchCvView) ? 'UserCv' : 'UserVita';
    },

    cvProps() {
      return {
        uuid: this.user.uuid,
        keep_old_cv: this.user.keep_old_cv,
        ...(this.showCvVersion === 'UserCv') && {
          cv_entries: this.user.cv_entries,
          oldCvLink: !!this.user.vita,
        },
        ...(this.showCvVersion === 'UserVita') && {
          vita: this.user.vita,
          useTemplate: true,
        },
      };
    },
  },
  methods: {
    handleCvSwitch() {
      this.switchCvView = !this.switchCvView;
    },

    fetchUser() {
      this.$store.dispatch('isLoggedIn')
        .then((token, refreshed) => {
          if (!refreshed) {
            this.$store.dispatch('fetchUserData');
            this.$store.dispatch('fetchAvatar', true);
          }
        });
    },
  },
  components: {
    Background,
    ManagerDocumentManager,
    ManagerDailyRate,
    ManagerProfileInfo,
    ManagerSkills,
    UserAvailability,
    UserCategories,
    UserHeader,
    UserLanguageSkills,
    UserMeta,
    UserWillingnessToTravel,
    SubscribeMarketingMails,
    UserCv: () => import('@/components/modules/User/UserCv.vue'),
    UserVita: () => import('@/components/modules/User/UserVita.vue'),
    UserEducation: () => import('@/components/modules/User/UserEducation.vue'),
  },
};
</script>
