<template>
  <section class="project-meta  -public">
    <ContentBox v-if="projectMeta.title">
      <div class="row">
        <div class="col-24">
          <h4 class="font-weight-normal  mt-xs  mb-m  mt-xs-0  mb-xs-xs" :class="{'font-size-xl': $matchMedia.xs}">{{ $t('projectDetails.headline') }}</h4>
        </div>
      </div>
      <ContentBox class="mb-xs">
        <div class="row  mb-xs">
          <div class="col-24">
            <div class="display-flex  align-items-center">
              <Icon class="-inline  -is-left">
                <FileSVG></FileSVG>
              </Icon>
              <h6 class="font-weight-medium  mb-0" :class="{'font-size-l': $matchMedia.xs}">
                {{ $t('projectMeta.subheading') }}
              </h6>
            </div>
          </div>
        </div>
        <div class="row  mb-xs">
          <div class="col-8  col-xs-24">
            <div class="project-meta-label">{{ $t('projectMeta.inputs.start.label') }}</div>
            <div class="project-meta-text">{{ $t(`projectMeta.inputs.start.values.${projectMeta.start}`) }}</div>
          </div>
          <div class="col-8  col-xs-24">
            <div class="project-meta-label">{{ $t('projectMeta.inputs.contract.label') }}</div>
            <div class="project-meta-text">{{ $t(`projectMeta.inputs.contract.values.${projectMeta.contract}`) }}</div>
          </div>
          <div class="col-8  col-xs-24">
            <div class="project-meta-label">{{ $t('projectMeta.inputs.country.label') }}</div>
            <div class="project-meta-text">{{ $t(`projectMeta.inputs.country.values.${projectMeta.country}`) }}</div>
          </div>
        </div>
        <div class="row  mb-xs">
          <div class="col-8  col-xs-24">
            <div class="project-meta-label">{{ $t('projectMeta.inputs.duration.label') }}</div>
            <div class="project-meta-text">{{ $t(`projectMeta.inputs.duration.values.${projectMeta.duration}`) }}</div>
          </div>
          <div class="col-8  col-xs-24">
            <div class="project-meta-label">{{ $t('projectMeta.inputs.experience.label') }}</div>
            <div class="project-meta-text">{{ $t(`categories.experiences.${projectMeta.experience}`) }}</div>
          </div>
          <div class="col-8  col-xs-24">
            <div class="project-meta-label">{{ $t('projectMeta.inputs.zipcode.label') }}</div>
            <div class="project-meta-text">{{ projectMeta.zipcode }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-8  col-xs-24">
            <div class="project-meta-label">{{ $t('projectMeta.inputs.availability.label') }}</div>
            <div class="project-meta-text">{{ $t(`projectMeta.inputs.availability.values.${projectMeta.availability}`) }}</div>
          </div>
          <div class="col-8  col-xs-24">
            <div class="project-meta-label">{{ $t('projectMeta.inputs.dailyRate.label') }}</div>
            <div v-if="projectMeta.daily_price_show" class="project-meta-text">{{ projectMeta.daily_price_from }} - {{ projectMeta.daily_price_to }}</div>
            <div v-else class="project-meta-text">{{ $t('status.data.unset') }}</div>
          </div>
          <div class="col-8  col-xs-24">
            <div class="project-meta-label">{{ $t('projectMeta.inputs.application_deadline.label') }}</div>
            <div class="project-meta-text">{{ projectMeta.application_deadline | formatDate }}</div>
          </div>
        </div>
      </ContentBox>
      <div class="row  mt-xs">
        <div class="col-12  col-xs-24  mb-xs-xs">
          <ContentBox>
            <div class="display-flex  align-items-center">
              <Icon class="-inline  -is-left">
                <BadgeSVG></BadgeSVG>
              </Icon>
              <h6 class="font-weight-medium  mb-0" :class="{'font-size-l': $matchMedia.xs}">
                {{ $t('projectDetails.operationals.headline') }}
              </h6>
            </div>
            <div class="row">
              <div class="col-24">
                <ul class="pl-xs  pl-xs-xxs  -custom-bullets-list  mb-0  mt-s">
                  <li class="mb-s" v-for="(operation, index) in projectMeta.main_operationals" :key="index">
                    <h6 v-if="projectMeta.main_operationals[index]" class="h6  -custom-bullet  display-inline  font-size-base  font-weight-medium  mb-0">
                      {{ $t(`categories.operationals.main.${projectMeta.main_operationals[index]}`) }}
                    </h6>
                    <ul v-if="projectMeta.sub_operationals[index]" class="mb-0">
                      <li class="ml-xs  mb-0  display-flex  align-items-center">
                        <Icon class="color-blue-300  -inline  -is-left">
                          <ReturnSVG></ReturnSVG>
                        </Icon>
                        <h6 class="h6  font-size-base  font-weight-medium  mb-0">
                          {{ $t(`categories.operationals.sub.${projectMeta.sub_operationals[index].replace('_', '.')}`) }}
                        </h6>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </ContentBox>
        </div>
        <div class="col-12  col-xs-24">
          <ContentBox>
            <div class="display-flex  align-items-center">
              <Icon class="-inline  -is-left">
                <IndustrySVG></IndustrySVG>
              </Icon>
              <h6 class="font-weight-medium  mb-0" :class="{'font-size-l': $matchMedia.xs}">
                {{ $t('projectDetails.industry.headline') }}
              </h6>
            </div>
            <div class="row">
              <div class="col-24">
                <ul class="pl-xs  pl-xs-xxs  -custom-bullets-list  mb-0  mt-s">
                  <li class="mb-s">
                    <h6 class="h6  -custom-bullet  font-size-base  font-weight-medium  mb-0">
                      {{ $t(`categories.industries.main.${projectMeta.main_industry}`) }}
                    </h6>
                    <ul v-if="projectMeta.sub_industry" class="mb-0">
                      <li class="ml-xs  mb-0  display-flex  align-items-center">
                        <Icon class="color-blue-300  -inline  -is-left">
                          <ReturnSVG></ReturnSVG>
                        </Icon>
                        <h6 class="h6  font-size-base  font-weight-medium  mb-0">
                          {{ $t(`categories.industries.sub.${projectMeta.main_industry}.${projectMeta.sub_industry}`) }}
                        </h6>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </ContentBox>
        </div>
      </div>
    </ContentBox>
  </section>
</template>

<script>
import parseDates from '@/mixins/parseDates';

import ContentBox from '@/components/elements/ContentBox.vue';
import Icon from '@/components/atoms/Icon.vue';
import IndustrySVG from '@/assets/icons/industry.svg';
import FileSVG from '@/assets/icons/file.svg';
import BadgeSVG from '@/assets/icons/badge.svg';
import ReturnSVG from '@/assets/icons/keyboard-return.svg';

export default {
  name: 'ProjectMeta',
  mixins: [parseDates],
  props: {
    project: Object,
  },
  data() {
    return {
      projectMeta: {},
      projectDetails: {
        main_operationals: [],
        sub_operationals: [],
      },
    };
  },
  mounted() {
    this.initMeta();
  },
  methods: {
    initMeta() {
      this.projectMeta = {
        title: this.project.title,
        start: this.project.start,
        duration: this.project.duration,
        application_deadline: (this.project.application_deadline) ? this.project.application_deadline.split('T')[0] : '',
        availability: this.project?.availability,
        contract: this.project.contract,
        country: this.project.country,
        zipcode: this.project.zipcode,
        experience: this.project.experience,
        daily_price_from: this.project.daily_price_from,
        daily_price_to: this.project.daily_price_to,
        daily_price_show: this.project.daily_price_show,
        main_operationals: [...(this.project.main_operationals || [])],
        sub_operationals: [...(this.project.sub_operationals || [])],
        main_industry: this.project.main_industry,
        sub_industry: this.project.sub_industry,
      };
    },
  },
  components: {
    ContentBox,
    IndustrySVG,
    FileSVG,
    BadgeSVG,
    Icon,
    ReturnSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/project-meta.scss"></style>
