<template>
  <div class="project-detail">
    <div class="row">
      <div class="col-24">
        <ProjectHeader :project="project" v-if="project.id"></ProjectHeader>
      </div>
      <div class="col-18  col-lg-16  col-md-24">
        <ProjectMeta ref="meta" v-if="project.id" :project="project"></ProjectMeta>
        <ProjectContent ref="content" v-if="project.id" :project="project">
          <template v-slot:applyCta>
            <div class="row" v-if="!$route.path.includes('public')">
              <div class="col-24  text-right">
                <Button @click.native="$eventBus.$emit('open-modal-projectDetail')"
                        v-if="!closed"
                        class="-orange">
                  {{ $t('projectContent.buttons.apply') }}
                </Button>
              </div>
            </div>
          </template>
        </ProjectContent>
      </div>
      <div class="col-6  col-lg-8  col-md-24">
        <ContentBox>
          <h5 class="font-weight-medium  color-brand-blue  mb-xs-xs" :class="{'font-size-xl': $matchMedia.sm}">{{ $t('projectDetails.prompt.headline') }}</h5>
          <Button @click.native="$eventBus.$emit('open-modal-projectDetail')"
                  :class="['-orange', { '-disabled': closed , '-full-width  text-center': $matchMedia.xs}]">
            <template v-if="!closed">{{ $t('projectDetails.prompt.button.apply') }}</template>
            <template v-else>{{ $t('projectDetails.prompt.button.closed') }}</template>
          </Button>
          <hr class="color-blue-100  mt-0  mb-0  mt-xs-xs  mb-xs-xs" v-if="$matchMedia.sm">
          <h6 class="font-weight-medium  mt-m  mt-xs-0  mb-xs-xxs" :class="{'font-size-s': $matchMedia.sm}">{{ $t('projectDetails.prompt.subHeadline') }}</h6>
          <div class="font-weight-normal  font-size-base"  v-html="$t('projectDetails.prompt.support')"></div>
        </ContentBox>
      </div>
    </div>
      <ProjectApplication v-if="project.id" :project="project" />
  </div>
</template>

<script>
import { getProject } from '@/api/projects.api';

import Button from '@/components/atoms/Button.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import ProjectHeader from '@/components/modules/Project/public/Header.vue';
import ProjectMeta from '@/components/modules/Project/public/Meta.vue';
import ProjectContent from '@/components/modules/Project/public/Content.vue';
import ProjectApplication from '@/components/modules/Project/public/Application.vue';

export default {
  name: 'UserProjectDetail',
  props: {
    id: Number,
  },
  data() {
    return {
      project: {},
    };
  },
  computed: {
    closed() {
      return (new Date(this.project.application_deadline).getTime() < (new Date().getTime())) || this.project.status === 'closed' || this.project.status === 'staffed';
    },
  },
  mounted() {
    this.fetchProject();
  },
  methods: {
    fetchProject() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          getProject(parseInt(this.id, 10), token)
            .then((response) => response.data)
            .then(({ data }) => {
              this.$set(this, 'project', data);
            })
            .catch((error) => {
              if (error.response.status === 404) {
                this.$router.replace({ name: 'NotFound', params: { lang: this.$i18n.locale } });
              }
            });
        });
    },
  },
  components: {
    Button,
    ContentBox,
    ProjectHeader,
    ProjectMeta,
    ProjectContent,
    ProjectApplication,
  },
};
</script>
