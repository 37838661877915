<template>
  <section class="application">
    <component v-bind:is="currentStatus"></component>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Application',
  computed: {
    ...mapState([
      'status',
    ]),

    currentStatus() {
      if (this.status === 'self-assessment') {
        return 'SelfAssessment';
      }

      return 'StatusInfo';
    },
  },
  components: {
    SelfAssessment: () => import('@/components/modules/User/application/SelfAssessment.vue'),
    StatusInfo: () => import('@/components/modules/User/application/StatusInfo.vue'),
  },
};
</script>
