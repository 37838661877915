<template>
<Modal id="projectDetail" :title="$t('projectApplication.coverLetter.apply')">
  <div class="project-application  project-application-table">
    <FormElement enctype="multipart/form-data" @submit="handleSubmit" v-if="!submitStatus">
      <div class="row">
        <div class="col-18  col-md-24">
          <ContentBox>
            <div class="row">
              <div class="col-24  col-sm-24  mb-s">
                <h6 class="font-weight-medium">{{ $t('projectApplication.coverLetter.headline') }}</h6>
                <div class="font-size-base  font-weight-normal" v-html="$t('projectApplication.coverLetter.description')"></div>
              </div>
              <div class="col-24">
                <InputTextarea name="message"
                               base="projectApplication.coverLetter"
                               :class="{ '-has-error': $v.message.$error }"
                               :rows="4"
                               v-model="$v.message.$model">
                </InputTextarea>
              </div>
            </div>
          </ContentBox>
          <ContentBox class="mt-s  mb-s">
            <div class="row">
              <div class="col-12  col-sm-24">
                <h6 class="font-weight-medium">{{ $t('projectApplication.profile.headline') }}</h6>
                <p class="font-weight-normal  font-size-base">{{ $t('projectApplication.profile.description') }}</p>
                <ol>
                  <li v-for="(requirement, index) in $t('projectApplication.profile.requirements')" :key="index" v-html="requirement"></li>
                </ol>
              </div>
              <div class="col-12  col-sm-24">
                <div class="_inner-box">
                  <p>{{ $t('projectApplication.profile.inputs.documents.label') }}</p>
                  <FilePicker
                    name="documents"
                    base="projectApplication.profile"
                    :files="documents"
                    :class="{ '-has-error': $v.selectedDocument.$error }"
                    v-model="$v.selectedDocument.$model"
                    @show-file="getDocument($event.file)"
                    @input="willUploadNewDocument = false; resetFileInput()"
                  />
                </div>
                <div class="_inner-box">
                  <InputCheckbox base="projectApplication.profile"
                                 name="fileUpload"
                                 class="-dark mb-xs"
                                 @input="willUploadNewDocument ? selectedDocument = '' : ''"
                                 v-model="willUploadNewDocument">
                  </InputCheckbox>

                  <div class="pl-s  mt-s">
                    <InputFile base="projectApplication.profile"
                               name="newDocument"
                               accept=".pdf"
                               class="-blue"
                               :class="{ '-has-error': $v.newDocument.$error }"
                               v-model="$v.newDocument.$model"
                               @input="willUploadNewDocument = true"
                    />
                  </div>

                </div>
              </div>
            </div>
          </ContentBox>
        </div>
        <div class="col-6  col-md-24">
          <ContentBox>
            <div class="row  mb-m">
              <div class="col-24">
                <h6 class="font-weight-medium">{{ $t('projectApplication.userMeta.headline') }}</h6>
              </div>
              <div class="col-24  mb-s">
                <table>
                  <tr>
                    <td>
                      {{ $t('projectApplication.userMeta.inputs.formalTitle.label') }}:
                    </td>
                    <td>
                      <InputSelect alt="small"
                                   :class="{ '-has-error': $v.localUserMeta.formalTitle.$error }"
                                   name="formalTitle"
                                   base="projectAppliaction.userMeta"
                                   :options="$t('projectApplication.userMeta.inputs.formalTitle.values')"
                                   v-model="$v.localUserMeta.formalTitle.$model"></InputSelect>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="col-24  mb-s">
                <table>
                  <tr>
                    <td>
                      {{ $t('projectApplication.userMeta.inputs.firstname.label') }}:
                    </td>
                    <td>
                      <InputText alt="small"
                                 :class="{ '-has-error': $v.localUserMeta.firstname.$error }"
                                 type="text"
                                 name="firstname"
                                 base="projectApplication.userMeta"
                                 v-model="$v.localUserMeta.firstname.$model"></InputText>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t('projectApplication.userMeta.inputs.lastname.label') }}:
                    </td>
                    <td>
                      <InputText alt="small"
                                 :class="{ '-has-error': $v.localUserMeta.lastname.$error }"
                                 type="text"
                                 name="lastname"
                                 base="projectApplication.userMeta"
                                 v-model="$v.localUserMeta.lastname.$model"></InputText>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="col-24">
                <table>
                  <tr>
                    <td>
                      {{ $t('projectApplication.userMeta.inputs.email.label') }}:
                    </td>
                    <td>
                      <p class="pt-xxs  pb-xxs">{{ localUserMeta.email }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t('projectApplication.userMeta.inputs.phone.label') }}:
                    </td>
                    <td>
                      <InputText alt="small"
                                 :class="{ '-has-error': $v.localUserMeta.phone.$error }"
                                 type="phone"
                                 name="phone"
                                 base="projectApplication.userMeta"
                                 v-model="$v.localUserMeta.phone.$model"></InputText>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t('projectApplication.userMeta.inputs.daily_price.label') }}:
                    </td>
                    <td>
                      <InputCurrency alt="small"
                                     :class="{ '-has-error': $v.daily_price.$error }"
                                     type="number"
                                     base="projectApplication.userMeta"
                                     name="daily_price"
                                     v-model="$v.daily_price.$model">
                      </InputCurrency>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </ContentBox>
        </div>
      </div>
    </FormElement>
    <transition name="fade">
        <div class="_submit-success" v-if="submitStatus === SUCCESS">
          <h6 class="h6  mb-s">{{ $t('projectApplication.submit.success.headline') }}</h6>
          <p v-html="$t('projectApplication.submit.success.paragraph', [project.title])"></p>
        </div>
        <div class="_submit-error" v-if="submitStatus === DUPLICATE">
          <h6 class="h6  mb-s">{{ $t('projectApplication.submit.duplicate.headline') }}</h6>
          <p v-html="$t('projectApplication.submit.duplicate.paragraph', [project.title])"></p>
        </div>
    </transition>
  </div>
  <template slot="footer">
    <div v-if="!submitStatus"
         class="col-18 col-md-24 -no-padding"
    >
      <div class="text-right valign-middle">
        <Button class="button -orange -outlined mr-s" @click.native="cancel">
          {{ $t('managerProfileInfo.buttons.cancel') }}
        </Button>
        <Button class="-orange" @click.native="handleSubmit">
          {{ $t('projectApplication.buttons.submit') }}
        </Button>
      </div>
    </div>
    <div v-if="[SUCCESS, DUPLICATE].includes(submitStatus)"
         class="col-24 valign-middle text-right -no-padding"
    >

      <Button class="-orange" @click.native="cancel">
        {{ $t('projectApplication.buttons.close') }}
      </Button>
    </div>
  </template>
</Modal>
</template>

<script>
import { mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required,
  email,
  minValue,
  requiredIf,
} from 'vuelidate/lib/validators';

import emailFormatter from '@/mixins/emailFormatter';
import { applyProject } from '@/api/projects.api';
import {
  getDocuments,
  uploadDocument,
  getDocument,
} from '@/api/users.api';

import Button from '@/components/atoms/Button.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import FormElement from '@/components/elements/FormElement.vue';
import FilePicker from '@/components/elements/FilePicker.vue';
import InputCurrency from '@/components/elements/inputs/InputCurrency.vue';
import InputCheckbox from '@/components/elements/inputs/InputCheckbox.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';
import InputText from '@/components/elements/inputs/InputText.vue';
import InputFile from '@/components/elements/inputs/InputFile.vue';
import InputTextarea from '@/components/elements/inputs/InputTextarea.vue';
import Modal from '@/components/elements/Modal.vue';

const status = {
  SUCCESS: 'SUCCESS',
  DUPLICATE: 'DUPLICATE',
};

export default {
  name: 'ProjectApplication',
  mixins: [validationMixin],
  props: {
    project: Object,
  },
  data() {
    return {
      SUCCESS: status.SUCCESS,
      DUPLICATE: status.DUPLICATE,
      message: '',
      selectedDocument: '',
      willUploadNewDocument: false,
      newDocument: null,
      daily_price: 0,
      submitStatus: false,
      localUserMeta: {
        email: '',
        firstname: '',
        lastname: '',
        formalTitle: '',
        phone: '',
      },
      documents: [],
    };
  },
  computed: {
    ...mapState([
      'user',
      'usermeta',
    ]),
  },
  mounted() {
    this.daily_price = this.user.dailyRate || 0;
    this.initLocalUserMeta();
    this.getDocumentList();

    if (this.documents.length === 0) {
      this.willUploadNewDocument = true;
    }
  },
  validations: {
    localUserMeta: {
      email: {
        required,
        email: (val) => email(emailFormatter(val)),
      },
      firstname: { required },
      lastname: { required },
      formalTitle: { required },
      phone: { required },
    },
    daily_price: {
      required,
      minValue: minValue(1),
    },
    message: { required },
    selectedDocument: {
      required: (value, vm) => value || vm.willUploadNewDocument,
    },
    willUploadNewDocument: {
      required: (value, vm) => value || vm.selectedDocument,
    },
    newDocument: {
      required: requiredIf('willUploadNewDocument'),
    },
  },
  methods: {
    initLocalUserMeta() {
      this.localUserMeta = {
        email: this.usermeta.email,
        firstname: this.usermeta.firstname,
        lastname: this.usermeta.lastname,
        formalTitle: this.user.formalTitle,
        phone: this.user.phone,
      };
      this.daily_price = this.user.dailyRate || 0;
    },

    cancel() {
      this.initLocalUserMeta();
      this.message = '';
      this.submitStatus = false;
      this.selectedDocument = '';
      this.newDocument = '';
      this.willUploadNewDocument = false;
      this.resetFileInput();

      this.$v.$reset();
      this.$eventBus.$emit('close-modal-projectDetail', true);
    },

    handleSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.willUploadNewDocument) {
          this.uploadDocument().then((document) => {
            this.submitApplication(document.id);
          });
        } else {
          this.submitApplication(this.selectedDocument);
        }
      }
    },

    submitApplication(documentId) {
      this.$store.dispatch('isLoggedIn')
        .then((token) => {
          applyProject(this.project.id, {
            uuid: this.user.uuid,
            applicationData: {
              ...this.localUserMeta,
              daily_price: this.daily_price,
              message: this.message,
              document_id: documentId,
            },
          }, token)
            .then(() => {
              this.submitStatus = status.SUCCESS;
              this.$eventBus.$emit('notificate');
            })
            .catch((error) => {
              if (error.response.status === 403 && error.response.data.message === status.DUPLICATE.toLowerCase()) {
                this.submitStatus = status.DUPLICATE;
              } else {
                this.$eventBus.$emit('notificate', { message: error.response.data.message, status: error.response.status });
              }
            });
        });
    },

    onFileAdded(file) {
      this.willUploadNewDocument = true;
      this.newDocument = file.name;
    },

    resetFileInput() {
      this.newDocument = null;
    },

    getDocumentList() {
      this.$store.dispatch('isLoggedIn')
        .then((token) => getDocuments(this.user.uuid, token))
        .then((response) => {
          this.documents = response.data.data;
        });
    },

    uploadDocument() {
      return this.$store.dispatch('isLoggedIn')
        .then((token) => {
          const file = new FormData();
          file.append('document', this.newDocument);
          return uploadDocument(this.user.uuid, file, token);
        })
        .then((result) => {
          this.$eventBus.$emit('notificate', {
            title: this.$t('notifications.managerDocumentManager.document_uploaded.title'),
            message: this.$t('notifications.managerDocumentManager.document_uploaded.message'),
          });
          this.getDocumentList();
          this.newDocument = null;
          return result.data.data;
        })
        .catch((error) => {
          console.error(error);
          this.$eventBus.$emit('notificate', {
            status: error.response.status,
            message: error.response.message,
          });
        });
    },

    getDocument(document) {
      const cvWindow = window.open();

      this.$store.dispatch('isLoggedIn')
        .then((token) => getDocument(this.user.uuid, document.filename, token, true))
        .then((response) => {
          const file = new Blob([response.data], { type: response.data.type });
          cvWindow.location.href = URL.createObjectURL((file));
        })
        .catch(() => {
          cvWindow.location.href = '/404';
          this.$eventBus.$emit('notificate', { status: 404, title: this.$t('userMeta.fields.managerPdf.error') });
        });
    },
  },
  components: {
    Button,
    ContentBox,
    FormElement,
    FilePicker,
    InputCurrency,
    InputCheckbox,
    InputSelect,
    InputText,
    InputTextarea,
    InputFile,
    Modal,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/project-application.scss"></style>
