<template>
  <div class="users-nav">
    <nav class="mb-m">
      <li>
        <router-link :to="{ name: 'Profile', params: { lang: this.$i18n.locale } }" exact>
          <Icon class="-inline  pr-xs  pr-sm-0"><ManagersSVG></ManagersSVG></Icon>
          {{ $t('navigations.manager.profile') }}
        </router-link>
      </li>
      <li v-if="!isCompleted">
        <router-link :to="{ name: 'Application', params: { lang: this.$i18n.locale } }">
          <Icon class="-inline  pr-xs  pr-sm-0"><ApplicationSVG></ApplicationSVG></Icon>
          {{ $t('navigations.manager.applicationInfo') }}
        </router-link>
      </li>
      <li v-if="selfAssessed" :class="{ '-disabled': !isCompleted }">
        <template v-if="!isCompleted">
          <span class="-link-disabled">
            <Icon class="-inline  pr-xs  pr-sm-0"><ProjectsSVG></ProjectsSVG></Icon>
            {{ $t('navigations.manager.projects') }}
          </span>
        </template>
        <template v-if="isCompleted">
          <router-link :to="{ name: 'Projects', params: { lang: this.$i18n.locale } }">
            <Icon class="-inline  pr-xs  pr-sm-0"><ProjectsSVG></ProjectsSVG></Icon>
            {{ $t('navigations.manager.projects') }}
          </router-link>
        </template>
      </li>
    </nav>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon.vue';
import ApplicationSVG from '@/assets/icons/application.svg';
import ManagersSVG from '@/assets/icons/managers.svg';
import ProjectsSVG from '@/assets/icons/file.svg';

export default {
  name: 'ManagerNav',
  props: {
    status: String,
    groups: Array,
  },
  computed: {
    isCompleted() {
      return !this.groups.includes('pending');
    },

    selfAssessed() {
      return this.status !== 'self-assessment';
    },
  },
  components: {
    Icon,
    ApplicationSVG,
    ManagersSVG,
    ProjectsSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/07_elements/users-nav.scss"></style>
